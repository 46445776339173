import GATSBY_COMPILED_MDX from "/opt/build/repo/content/aktualnosci/dofinansowanie-do-budowy-domu-jak-uzyskać.mdx";
import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import {GatsbyImage} from 'gatsby-plugin-image';
import {MDXProvider} from "@mdx-js/react";
import Seo from "../components/seo";
import AnimationWrapper from "../components/animationWrapper";
import Button from "../components/button";
import ContactSection from "../components/contactSection";
import Container from "../components/container";
import Title from "../components/title";
import Layout from "../components/layout";
import "./blog-post.css";
const BlogPost = ({data, location, children}) => {
  const header = data.page.frontmatter.header;
  header.title = data.post.frontmatter.title;
  const file = data.post.internal.contentFilePath.split('/').pop();
  const postSlug = file.slice(0, file.lastIndexOf('.'));
  const metaDescription = data.post.frontmatter.meta_description || `Czamaninek ➤ ${data.post.frontmatter.title} ⭐ Czytaj!`;
  const MyH2 = props => React.createElement("h2", Object.assign({
    className: "section-heading"
  }, props));
  const components = {
    h2: MyH2,
    Button: Button
  };
  const getYouTubeVideoId = url => {
    const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(pattern);
    return match && match[1];
  };
  let videoID;
  if (data.post.frontmatter.featured_video) {
    videoID = getYouTubeVideoId(data.post.frontmatter.featured_video);
  }
  return React.createElement(Layout, {
    location: location,
    path: postSlug
  }, React.createElement(Seo, {
    title: `${data.post.frontmatter.title} - ${data.page.frontmatter.title}`,
    index: data.post.frontmatter.index,
    description: metaDescription
  }), React.createElement(Container, {
    className: "blog-post-container"
  }, videoID ? React.createElement(AnimationWrapper, {
    className: "youtube-wrapper"
  }, React.createElement("iframe", {
    width: "100%",
    height: "100%",
    src: `https://www.youtube.com/embed/${videoID}`,
    title: "YouTube video player",
    frameborder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
    allowFullScreen: true
  })) : React.createElement(AnimationWrapper, null, data.post.frontmatter.image_main && React.createElement("div", {
    className: "main-image-wrapper"
  }, React.createElement(GatsbyImage, {
    image: data.post.frontmatter.image_main.childImageSharp.gatsbyImageData,
    objectFit: data.post.frontmatter.image_object_fit ? data.post.frontmatter.image_object_fit : "cover",
    objectPosition: data.post.frontmatter.image_object_position ? data.post.frontmatter.image_object_position : "50% 50%",
    alt: data.post.frontmatter.title
  }))), React.createElement(AnimationWrapper, null, React.createElement("div", {
    className: "blog-post"
  }, React.createElement(Title, {
    className: `blog-title`,
    title: data.post.frontmatter.title,
    headingTag: "h1"
  }), React.createElement("div", {
    className: `blog-post-content description-medium ${data.post.frontmatter.align}`
  }, React.createElement("div", {
    className: "section-description"
  }, React.createElement(MDXProvider, {
    components: components
  }, children)))))), React.createElement("section", {
    id: "contact-data",
    className: "page-section"
  }, React.createElement(ContactSection, {
    data: data.contactSection.frontmatter.contact_section
  })));
};
export const pageQuery = graphql`
  query(
      $id: String!,
  ) {
    page: mdx(frontmatter: { slug: { eq: "blog-post" }, type: { eq: "page" } }) {
      frontmatter{
        title
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    post: mdx(id: { eq: $id }) {
      id
      excerpt
      internal {
        contentFilePath
      }
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        title
        index
        # description
        featured_video
        align
        image_main {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {fit:COVER}
              webpOptions: {quality:95}
            )
          }
        }
        image_object_fit
        # image_object_position
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
  }
`;
BlogPost.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.object
  })
};
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
